import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { FC } from "react";

import ArrowDown from "../assets/icons/arrowDown.svg?react";
import Cross from "../assets/icons/cross.svg?react";
import "../styles/components/selectList.css";

export interface ISelectedListItem {
  name: string;
  id: string | number;
  value: string | number;
}

interface ISelectedList {
  elements: ISelectedListItem[];
  active?: ISelectedListItem;
  handleChange: (s: ISelectedListItem) => void;
  placeholder?: string;
  label?: string;
  required?: boolean;
  handleClear?: () => void;
}

export const SelectList: FC<ISelectedList> = ({
  active,
  elements,
  handleChange,
  placeholder,
  label,
  required,
  handleClear,
}) => {
  return (
    <Listbox value={active} onChange={handleChange}>
      <div className="select-list-wrapper">
        {label && (
          <div className="selected-list-label">
            {label} {required && <span className="text-red-600">*</span>}
          </div>
        )}
        <ListboxButton className="select-list-selected-item">
          <span className="select-list-selected-item-name">
            {active?.name || placeholder || "Не выбрано"}{" "}
          </span>
          {handleClear && active?.name && (
            <button
              onClick={(e) => {
                e.preventDefault();
                handleClear();
              }}
              className="inline-flex items-center"
            >
              <Cross />
            </button>
          )}
          {((handleClear && !active?.name) || !handleClear) && <ArrowDown />}
        </ListboxButton>
        <ListboxOptions
          transition
          className="data-[closed]:scale-95 data-[closed]:opacity-0 select-list-options"
          anchor="bottom"
        >
          {elements?.map((i) => (
            <ListboxOption
              key={i.id}
              value={i}
              className={`select-list-item select-list-item-active__${active?.id === i.id}`}
            >
              {i.name}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};
